import { ApolloClient } from 'apollo-client';
import { HttpLink } from 'apollo-link-http';
import { InMemoryCache } from 'apollo-cache-inmemory';

import { onError } from 'apollo-link-error';
import { ApolloLink, Observable } from 'apollo-link';
import { TokenRefreshLink } from 'apollo-link-token-refresh';
// import jwtDecode from 'jwt-decode';
import axios from 'axios';
import { getHttpProtocol, getServerUrl } from '../utils/serverInfo';

export default store => {
  let httpProtocol = getHttpProtocol();
  // let wsProtocol = NODE_ENV === 'development' ? 'ws' : 'wss';
  let serverUrl = getServerUrl();

  const cache = new InMemoryCache({});

  const requestLink = new ApolloLink(
    (operation, forward) =>
      new Observable(observer => {
        let handle;
        Promise.resolve(operation)
          .then(operation => {
            // const accessToken = store.getState().auth.accessToken;
            // if (accessToken) {
            //   operation.setContext({
            //     headers: {
            //       'x-auth': `${accessToken}`
            //     }
            //   });
            // }
          })
          .then(() => {
            handle = forward(operation).subscribe({
              next: observer.next.bind(observer),
              error: observer.error.bind(observer),
              complete: observer.complete.bind(observer)
            });
          })
          .catch(observer.error.bind(observer));

        return () => {
          if (handle) handle.unsubscribe();
        };
      })
  );
  return new ApolloClient({
    link: ApolloLink.from([
      new TokenRefreshLink({
        accessTokenField: 'accessToken',
        isTokenValidOrUndefined: () => {
          // const token = getAccessToken();
          //   const accessToken = store.getState().auth.accessToken;
          //   if (!accessToken) {
          //     return true;
          //   }
          //   try {
          //     const { exp } = jwtDecode(accessToken);
          //     if (Date.now() >= exp * 1000) {
          //       return false;
          //     } else {
          //       return true;
          //     }
          //   } catch {
          //     return false;
          //   }
          return true;
        },
        fetchAccessToken: () => {
          return axios.post(
            `${httpProtocol}://${serverUrl}/refresh_token`,
            {},
            {
              withCredentials: true
            }
          );
        },
        handleFetch: accessToken => {
          //   store.dispatch(setSession({ accessToken }));
        },
        handleError: err => {
          console.warn('Your refresh token is invalid. Try to relogin');
          console.error(err);
        }
      }),
      onError(({ graphQLErrors, networkError }) => {}),
      requestLink,
      new HttpLink({
        uri: `${httpProtocol}://${serverUrl}/graphql`,
        credentials: 'include'
      })
    ]),
    cache
  });
};
