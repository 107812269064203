import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { ToastProvider } from 'react-toast-notifications';
import { ApolloProvider } from '@apollo/react-hooks';

import './App.scss';
import { Home } from './pages/Home';
import { Newsletter } from './pages/Newsletter/';
import { Terms } from './pages/Terms/';
import { Privacy } from './pages/Privacy/';
import { Support } from './pages/Support';
import { Classes } from './pages/Classes';
import { ClassesContainer } from './graphql/containers';
import client from './graphql/apollo';

function App() {
  return (
    <ApolloProvider client={client()}>
      <ToastProvider placement="bottom-right">
        <Router>
          <Switch>
            <Route path="/" exact>
              <Home />
            </Route>
            <Route path="/newsletter" exact>
              <Newsletter />
            </Route>
            <Route path="/terms" exact>
              <Terms />
            </Route>
            <Route path="/privacy" exact>
              <Privacy />
            </Route>
            <Route path="/support" exact>
              <Support />
            </Route>
            <Route path="/classes" exact>
              <ClassesContainer>
                {({ register, sendToastAlert, getClasses }) => (
                  <Classes
                    register={register}
                    sendToastAlert={sendToastAlert}
                    getClasses={getClasses}
                  />
                )}
              </ClassesContainer>
            </Route>
          </Switch>
        </Router>
      </ToastProvider>
    </ApolloProvider>
  );
}
export default App;
