import React from 'react';

import PropTypes from 'prop-types';
import { Col } from 'reactstrap';

const SectionHeader = ({ text, lead }) => (
  <Col
    md="12"
    className="d-flex flex-column align-items-center justify-content-center mb-3 text-center"
  >
    <h1 className="section-header">{text}</h1>
    {lead && <p className="section-lead ">{lead}</p>}
  </Col>
);
export default SectionHeader;

SectionHeader.propTypes = {
  text: PropTypes.string,
  lead: PropTypes.string
};
