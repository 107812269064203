import React, { useState, useEffect } from 'react';
import {
  Container,
  Collapse,
  Navbar,
  NavbarBrand,
  NavbarToggler,
  Nav,
  NavItem,
  NavLink,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap';
import { Link, useLocation } from 'react-router-dom';
import ScrollspyNav from 'react-scrollspy-nav';

const navItems = [
  {
    title: 'Services',
    href: 'services',
  },
  {
    title: 'Team',
    href: 'team',
  },
  {
    title: 'Clients',
    href: 'clients',
  },
  {
    title: 'Contact',
    href: 'contact',
  },
];
const Navigation = (props) => {
  const [isOpen, setIsOpen] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [isHome, setIsHome] = useState(true);
  const location = useLocation();

  useEffect(() => {
    if (location.pathname !== '/') {
      setIsHome(false);
    }
  }, [location.pathname]);
  const toggle = () => setDropdownOpen(!dropdownOpen);
  const NavigationItems = () => {
    return (
      <Container id="menu-container">
        <Navbar expand={'md'} color="light" light toggleable="md" fixed="top">
          <NavbarBrand href="#">
            <span>MUNCHDEV</span>
          </NavbarBrand>
          <NavbarToggler onClick={() => setIsOpen(!isOpen)} />
          <Collapse isOpen={isOpen} navbar>
            <Nav navbar className="ml-auto">
              <NavItem>
                <NavLink to="/" tag={Link} className="nav-link">
                  Home
                </NavLink>
              </NavItem>
              {navItems.map((n, index) => (
                <NavItem key={index}>
                  <NavLink href={`${isHome ? '' : '/'}#${n.href}`}>
                    {n.title}
                  </NavLink>
                </NavItem>
              ))}
              <Dropdown nav isOpen={dropdownOpen} toggle={toggle}>
                <DropdownToggle nav caret>
                  More
                </DropdownToggle>
                <DropdownMenu>
                  {/* <DropdownItem to="/classes" tag={Link}>
                    Classes
                  </DropdownItem> */}
                  <DropdownItem to="/newsletter" tag={Link}>
                    Newsletter
                  </DropdownItem>
                  <DropdownItem divider />
                  <DropdownItem to="/privacy" tag={Link}>
                    Privacy
                  </DropdownItem>
                  <DropdownItem to="/terms" tag={Link}>
                    Terms & Conditions
                  </DropdownItem>
                </DropdownMenu>
              </Dropdown>
            </Nav>
          </Collapse>
        </Navbar>
      </Container>
    );
  };

  return isHome ? (
    <ScrollspyNav
      scrollTargetIds={navItems.map((n) => n.href)}
      activeNavClass="active"
      router="BrowserRouter"
    >
      <NavigationItems />
    </ScrollspyNav>
  ) : (
    <NavigationItems />
  );
};
export default Navigation;
