import gql from 'graphql-tag';
const classes = `{
  id
  title
  type {
    id
    title
    slug
  }
  level {
    id
    title
    slug        
  }
  isPaidCourse
  isGroup
  classStartDate
  classStartTime
  description
  isRecurring
  recurrenceType
  duration
  occurences
  active
  price
}`;
export const CLASS_REGISTRATION = gql`
  mutation ClassRegistration($input: RegisterFreeInput!) {
    classRegistration(input: $input) {
      ok
      message
      errors {
        path
        message
      }
    }
  }
`;

export const GET_OPEN_CLASSES = gql`
  query GetOpenClasses {
    getOpenClasses {
      ok
      classes ${classes}
      errors {
        path
        message
      }
    }
  }
`;
