import React from 'react';

import { Navigation } from '../../components/Navigation';

import {
  Slideshow,
  Services,
  Team,
  Contact,
  Clients
} from '../../components/Section';
import ContactFormContainer from '../../graphql/containers/ContactFormContainer';

const Home = () => {
  return (
    <>
      <Navigation />
      <Slideshow />
      <Services />
      <Team />
      <Clients />
      <ContactFormContainer>
        {({ submit, sendToastAlert }) => (
          <Contact submit={submit} sendToastAlert={sendToastAlert} />
        )}
      </ContactFormContainer>
    </>
  );
};
export default Home;
