import React from 'react';
import PropTypes from 'prop-types';
import Icon from 'react-icons-kit';

const ServiceItem = ({ header, icon, description }) => {
  return (
    <>
      <div className="service-box mb-3">
        <Icon icon={icon} size="64" />
      </div>
      <h3 className="service-header">{header}</h3>
      <p className="service-text">{description}</p>
    </>
  );
};

export default ServiceItem;

ServiceItem.propTypes = {
  header: PropTypes.string,
  description: PropTypes.string,
  icon: PropTypes.object
};
