import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Carousel,
  CarouselItem,
  CarouselCaption,
  CarouselIndicators,
  CarouselControl
} from 'reactstrap';

const items = [
  {
    src: '/assets/images/slide-1.png',
    altText: 'Web Applications',
    captionHeader: 'web applications',
    captionText: 'Making the web work for you'
  },
  {
    src: '/assets/images/slide-2.png',
    altText: 'Mobile Applications',
    captionHeader: 'mobile applications',
    captionText: 'Keeping you connected'
  },
  {
    src: '/assets/images/slide-3.png',
    altText: 'Training & Instruction',
    captionHeader: 'training & instruction',
    captionText: 'We teach you to do what we do'
  }
];
const Slideshow = ({ text }) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [animating, setAnimating] = useState(false);

  const next = () => {
    if (animating) return;
    const nextIndex = activeIndex === items.length - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
  };

  const previous = () => {
    if (animating) return;
    const nextIndex = activeIndex === 0 ? items.length - 1 : activeIndex - 1;
    setActiveIndex(nextIndex);
  };

  const goToIndex = newIndex => {
    if (animating) return;
    setActiveIndex(newIndex);
  };

  const slides = items.map(item => {
    return (
      <CarouselItem
        onExiting={() => setAnimating(true)}
        onExited={() => setAnimating(false)}
        key={item.src}
      >
        <img src={item.src} alt={item.altText} />
        <CarouselCaption
          captionText={item.captionText}
          captionHeader={item.captionHeader}
          c
        />
      </CarouselItem>
    );
  });

  return (
    <Carousel
      activeIndex={activeIndex}
      next={next}
      previous={previous}
      className="d-none d-lg-block"
    >
      <CarouselIndicators
        items={items}
        activeIndex={activeIndex}
        onClickHandler={goToIndex}
      />
      {slides}
      <CarouselControl
        direction="prev"
        directionText="Previous"
        onClickHandler={previous}
      />
      <CarouselControl
        direction="next"
        directionText="Next"
        onClickHandler={next}
      />
    </Carousel>
  );
};
export default Slideshow;

Slideshow.propTypes = {
  text: PropTypes.string
};
