import { useMutation } from 'react-apollo';
import { withRouter } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';

import { CLASS_REGISTRATION } from '../queries/class';

const ClassContainer = (props) => {
  const [mutate] = useMutation(CLASS_REGISTRATION);

  const { addToast, removeAllToasts } = useToasts();

  async function register(values) {
    const { data } = await mutate({
      variables: values,
    });

    if (data) {
      return data.classRegistration;
    }
  }

  function sendToastAlert(message, options) {
    removeAllToasts();
    addToast(message, options);
  }

  return props.children({
    register,
    sendToastAlert,
  });
};

export default withRouter(ClassContainer);
