import React from 'react';
import PropTypes from 'prop-types';

const ClientItem = ({ client, className }) => (
  <div className={`client ${className}`}>
    <a href={client.url} target="_blank" rel="noopener noreferrer">
      <img
        src={client.imageUri}
        alt={client.caption}
        title={client.title}
      ></img>
    </a>
  </div>
);
export default ClientItem;

ClientItem.propTypes = {
  client: PropTypes.object,
  className: PropTypes.string
};
