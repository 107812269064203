import React from 'react';
import SectionHeader from '../../components/Section/SectionHeader';
import { Container, Row, Col } from 'reactstrap';

import { Navigation } from '../../components/Navigation';

const Support = () => {
  return (
    <>
      <Navigation />

      <section className="section-white page-container">
        <Container>
          <Row>
            <Col md="12" className="d-flex flex-column align-items-center">
              <SectionHeader text="Support" />
            </Col>
          </Row>
          <Row>
            <Col md="3" />
            <Col md="6" className="d-flex flex-column align-items-start">
              <blockquote>
                Lorem ipsum dolor sit amet consectetur adipisicing elit.
                Voluptate eaque neque odit incidunt veniam inventore, natus ea
                deserunt quae error aperiam ipsa saepe nisi pariatur architecto.
                Pariatur enim totam ullam, officia molestiae reprehenderit eius
                amet laboriosam omnis? Obcaecati soluta officiis, voluptatibus
                esse quos a saepe blanditiis. Earum, voluptatem doloremque.
                Eligendi, tempora nam, placeat corporis atque doloribus quisquam
                ea reiciendis amet dignissimos assumenda, alias quam autem.
                Expedita magnam iusto, debitis magni repellendus perspiciatis, a
                veniam dolor porro fugiat harum libero architecto iure
                necessitatibus facere dolorem illo obcaecati optio accusantium.
                Ut cum necessitatibus minus recusandae officia quo fugit
                aliquid? Aperiam eum beatae autem mollitia consequuntur dolore
                explicabo adipisci voluptates, dicta magnam dolor aut quis
                debitis fuga harum laboriosam, accusamus quia? Nulla recusandae
                sed eos iusto voluptates rem similique voluptas nemo inventore,
                quod quia? Minus amet iure error deserunt at, incidunt
                voluptatibus eaque expedita id aut quis, numquam doloremque
                quidem cumque autem perferendis praesentium quaerat cum
                temporibus velit ut? Tenetur cum ab molestiae velit quis
                incidunt aperiam officiis odio, corrupti hic fugiat explicabo ut
                natus culpa eligendi nobis praesentium amet voluptatum tempora,
                quia, debitis ipsum magnam! Alias dolore nostrum iste facilis
                neque dignissimos quibusdam, assumenda illum odit aliquid
                dolorum ratione itaque ullam repellendus temporibus quasi
                quaerat! Perferendis est accusantium dolorum quibusdam atque
                asperiores quasi voluptas deleniti aliquam voluptatum id quo
                earum in, repellendus veniam dicta excepturi odio magnam
                architecto soluta sint animi exercitationem, omnis repudiandae.
                Dolores, corporis aspernatur soluta sint nobis odit iste placeat
                eaque minima est autem veniam dolore totam ducimus voluptatibus
                quos id facere necessitatibus inventore exercitationem voluptate
                esse modi molestias recusandae! Ducimus voluptatibus, at dolore
                corrupti expedita accusantium placeat obcaecati delectus
                voluptate ipsam nam facilis porro quos dignissimos nesciunt
                alias, impedit asperiores, hic nobis! Optio odit necessitatibus
                corrupti, eligendi vitae delectus sit quam, reprehenderit sed
                sequi veritatis deserunt velit dolor vero? Voluptates commodi
                quas libero possimus deleniti fugit optio? Placeat quia dolorem
                quam veniam quos fugit amet velit! Aliquam doloremque ipsam,
                ducimus eligendi delectus amet dolores quae enim, soluta
                provident voluptatibus, ullam fuga sapiente. Quod adipisci
                reprehenderit porro dignissimos officia, praesentium, nobis fuga
                explicabo quibusdam incidunt quo repellat similique consequatur
                fugit. Veritatis laudantium nulla asperiores accusantium
                reprehenderit at itaque, laboriosam consectetur eos quaerat
                cumque inventore, doloremque atque fugit voluptates! Quo
                laboriosam qui corrupti doloribus ipsum voluptate harum,
                reprehenderit sed iusto, reiciendis impedit! Voluptates atque
                tenetur in saepe quis. Eos sequi pariatur expedita veritatis
                ullam eligendi in amet, sunt dolor quo fuga deserunt consectetur
                reprehenderit libero ducimus minus ab ea, nulla temporibus
                quibusdam cupiditate architecto? Commodi nulla iste delectus. Ea
                architecto alias quidem corrupti eum fugiat odio, obcaecati
                rerum deleniti deserunt? Nulla quidem laborum soluta
                consequuntur molestias tempore iste perspiciatis sequi voluptas
                incidunt beatae tempora, nam similique, itaque maxime nesciunt
                vitae dolorum, adipisci doloribus assumenda! Necessitatibus
                optio sapiente modi nostrum provident nisi quasi ipsam debitis
                quos adipisci! Ab sunt harum dolor odit eaque in libero
                distinctio veritatis? Dicta neque sit praesentium aliquam? Quia
                ut cumque reprehenderit ea, dolor iusto sint esse culpa
                asperiores dolores architecto tempora quod molestias libero
                nostrum nam.
              </blockquote>
            </Col>
            <Col md="3" />
          </Row>
        </Container>
      </section>
    </>
  );
};
export default Support;
