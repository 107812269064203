const NODE_ENV =
  typeof process !== 'undefined' && process.env && process.env.NODE_ENV;

export const getServerUrl = () =>
  NODE_ENV === 'development'
    ? 'localhost:5000'
    : process.env.REACT_APP_GRAPHQL_URL;

export const getHttpProtocol = () => {
  return NODE_ENV === 'development'
    ? 'http'
    : process.env.REACT_APP_HTTP_PROTOCOL;
};
export const getWsProtocol = () => {
  return NODE_ENV === 'development' ? 'ws' : process.env.REACT_APP_WS_PROTOCOL;
};
