import React from 'react';
import {Container, Row, Col} from 'reactstrap';

import TeamItem from './TeamItem';
import SectionHeader from './SectionHeader';

const people = [
  {
    name: 'Michael Griffin',
    position: 'Owner, Lead Developer',
    social: [
      {type: 'twitter', handle: 'munchdev'},

      {type: 'github', handle: 'mike-munchdev'},
      {type: 'linkedin', handle: 'michael-griffin-mdev'},
      {type: 'facebook', handle: 'munchdev'},
      {type: 'instagram', handle: 'munchdev'},
      {type: 'youtube', handle: 'channel/UClfbNSFre5pOmEhyEpGpyRw'},
    ],
    imageUri: '/assets/images/people/michael-griffin-normal.jpg',
  },
];
const Team = (props) => {
  return (
    <section id="team" className="py-5 section-grey">
      <Container>
        <Row>
          <SectionHeader text="Our Team" />
          <Col
            md="12"
            className="d-flex align-items-center justify-content-center ">
            {people.map((p, index) => (
              <TeamItem member={p} key={index} className="mx-4" />
            ))}
          </Col>
        </Row>
      </Container>
    </section>
  );
};
export default Team;
