import React from 'react';

import { laptop } from 'react-icons-kit/fa/laptop';
import { mobilePhone } from 'react-icons-kit/fa/mobilePhone';
import { lightbulbO } from 'react-icons-kit/fa/lightbulbO';

import ServiceItem from './ServiceItem';
import SectionHeader from './SectionHeader';
import { Container, Row, Col } from 'reactstrap';

const Services = props => {
  return (
    <section id="services" className="section-white py-5">
      <Container>
        <Row>
          <Col md="12" className="d-flex flex-column align-items-center">
            <SectionHeader text="Services" />
          </Col>
          <Col
            md="4"
            className="mb-4 text-center d-flex flex-column align-items-center"
          >
            <ServiceItem
              header="Web Applications"
              description="Want to operate your business from the web? We have two decades of
          experience developing software applications for the web."
              icon={laptop}
            />
          </Col>
          <Col
            md="4"
            className="mb-4 text-center d-flex flex-column align-items-center"
          >
            <ServiceItem
              header="Mobile Applications"
              description="We create mobile applications for both iOS and Android devices.
            Make your app idea come to life by giving us a call!"
              icon={mobilePhone}
            />
          </Col>
          <Col
            md="4"
            className="mb-4 text-center d-flex flex-column align-items-center"
          >
            <ServiceItem
              header="Training & Instruction"
              description="Would you like to learn web and/or mobile development?  We've got you covered.  Request private lessons, join our weekly group classes online or join our classical coding camp!!"
              icon={lightbulbO}
            />
          </Col>
        </Row>
      </Container>
    </section>
  );
};
export default Services;
