import React from 'react';
import { Container, Row, Col } from 'reactstrap';

import ClientItem from './ClientItem';
import SectionHeader from './SectionHeader';

const clients = [
  {
    title: 'Advanced Reimbursment Solutions',
    caption: 'advancedreimbursement.us',
    url: 'http://advancedreimbursement.us',
    imageUri: '/assets/images/clients/ars.png',
  },
  {
    title: 'Cluey Consumer',
    caption: 'clueyconsumer.com/',
    url: 'https://clueyconsumer.com/',
    imageUri: '/assets/images/clients/cluey.jpg',
  },

  {
    title: "Banker's Bank of the West",
    caption: 'bbwest.com',
    url: 'http://bbwest.com',
    imageUri: '/assets/images/clients/bankersbank.png',
  },

  {
    title: 'Union Tank Car Company',
    caption: 'utlx.com',
    url: 'http://utlx.com',
    imageUri: '/assets/images/clients/utlx.png',
  },
  {
    title: 'Bisso Towboat Company',
    caption: 'bissotowing.com',
    url: 'http://www.bissotowing.com',
    imageUri: '/assets/images/clients/bisso.png',
  },

  {
    title: 'Nyansa Classical Community',
    caption: 'nyansaclassicalcommunity.org',
    url: 'http://nyansaclassicalcommunity.org',
    imageUri: '/assets/images/clients/nyansa.png',
  },
  {
    title: 'Major League Baseball',
    caption: 'mlb.com',
    url: 'http://www.mlb.com',
    imageUri: '/assets/images/clients/mlb.png',
  },
  {
    title: 'The Solutient Corporation',
    caption: 'solutient.com',
    url: 'http://www.solutient.com',
    imageUri: '/assets/images/clients/solutient.jpg',
  },
  {
    title: 'University of New Orleans',
    caption: 'uno.edu',
    url: 'http://www.uno.edu',
    imageUri: '/assets/images/clients/uno.png',
  },
  {
    title: 'Jefferson Transit',
    caption: 'jeffersontransit.org',
    url: 'http://www.jeffersontransit.org',
    imageUri: '/assets/images/clients/jet.png',
  },
];
const Client = props => {
  return (
    <section id="clients" className="py-5 section-blue">
      <Container>
        <Row>
          <SectionHeader text="Our Clients" />
          <Col
            md="12"
            className="d-flex align-items-center justify-content-center flex-wrap ">
            {clients.map((c, index) => (
              <ClientItem client={c} key={index} className="mx-2 my-2" />
            ))}
          </Col>
        </Row>
      </Container>
    </section>
  );
};
export default Client;
