import React, { useState, useEffect } from "react";
import { useQuery } from "react-apollo";

import {
  Container,
  Row,
  Col,
  FormGroup,
  Label,
  Input,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";

import StripeCheckout from "react-stripe-checkout";
import { omit } from "lodash";
import isEmail from "validator/lib/isEmail";
import { Navigation } from "../../components/Navigation";
import SectionHeader from "../../components/Section/SectionHeader";
import { GET_OPEN_CLASSES } from "../../graphql/queries/class";

const levels = [
  {
    id: 1,
    title: "Beginner",
    slug: "beginner",
  },
  {
    id: 2,
    title: "Intermediate",
    slug: "intermediate",
  },
  {
    id: 3,
    title: "Advanced",
    slug: "advanced",
  },
  {
    id: 4,
    title: "Master",
    slug: "master",
  },
];

const types = [
  {
    id: 1,
    title: "Web Development",
    slug: "web",
  },
  {
    id: 2,
    title: "Mobile Development",
    slug: "mobile",
  },
  {
    id: 3,
    title: "Desktop Development",
    slug: "desktop",
  },
];

const costs = [
  {
    id: 1,
    title: "Free",
    slug: "0",
  },
  {
    id: 2,
    title: "Paid",
    slug: "1",
  },
];

const Classes = (props) => {
  const [filteredClasses, setFilteredClasses] = useState([]);
  const [filters, setFilters] = useState({});
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedClass, setSelectedClass] = useState(null);
  const [registerEmail, setRegisterEmail] = useState("");
  const { loading, error, data } = useQuery(GET_OPEN_CLASSES, {
    onCompleted: (data) => {
      const { getOpenClasses } = data;
      if (getOpenClasses.ok) {
        setFilteredClasses(getOpenClasses.classes);
      }
    },
  });

  const toggle = () => setIsModalOpen(!isModalOpen);
  // const { sendToastAlert } = props;

  const filterClasses = (e) => {
    const { id, value } = e.target;

    if (value !== "") {
      setFilters({ ...filters, [id]: id === "cost" ? parseInt(value) : value });
    } else {
      const updatedFilters = omit(filters, id);
      setFilters(updatedFilters);
    }
  };

  useEffect(() => {
    if (data && data.getOpenClasses.ok) {
      const filteredClasses = data.getOpenClasses.classes.filter((c) => {
        for (let key in filters) {
          switch (key) {
            case "level":
              if (c.level.slug === undefined || c.level.slug !== filters[key])
                return false;
              break;
            case "type":
              if (c.type.slug === undefined || c.type.slug !== filters[key])
                return false;
              break;
            case "cost":
              if (
                c.isPaidCourse === undefined ||
                c.isPaidCourse !== Boolean(filters[key])
              )
                return false;
              break;
            default:
              if (c.level.slug === undefined || c.level.slug !== filters[key])
                return false;
              break;
          }
        }
        return true;
      });
      setFilteredClasses(filteredClasses);
    }
  }, [filters, data]);

  const registerForClass = (c) => {
    toggle();
    setRegisterEmail("");
  };

  if (loading) return <p>Loading...</p>;
  const { getOpenClasses } = data;

  if (!getOpenClasses.ok) return <p>Error!</p>;
  if (error) return <p>Error!</p>;
  // setFilteredClasses(getOpenClasses.classes);
  return (
    <>
      <Navigation />
      <section className="section-white page-container">
        <Container>
          <Row>
            <Col md="12" className="d-flex flex-column align-items-center">
              <SectionHeader text="Classes" />
            </Col>
          </Row>
          <Row>
            <Col md="1" />
            <Col md="10" className="d-flex flex-column align-items-start">
              <Row className="w-100 bg-light py-4 px-4">
                <Col md="4">
                  <FormGroup>
                    <Label for="level">Level</Label>
                    <Input
                      type="select"
                      name="select"
                      id="level"
                      onChange={filterClasses}
                    >
                      <option value="">All</option>
                      {levels.map((l) => (
                        <option key={l.id} value={l.slug}>
                          {l.title}
                        </option>
                      ))}
                    </Input>
                  </FormGroup>
                </Col>
                <Col md="4">
                  <FormGroup>
                    <Label for="type">Type</Label>
                    <Input
                      type="select"
                      name="select"
                      id="type"
                      onChange={filterClasses}
                    >
                      <option value="">All</option>

                      {types.map((t) => (
                        <option key={t.id} value={t.slug}>
                          {t.title}
                        </option>
                      ))}
                    </Input>
                  </FormGroup>
                </Col>
                <Col md="4">
                  <FormGroup>
                    <Label for="cost">Cost</Label>
                    <Input
                      type="select"
                      name="select"
                      id="cost"
                      onChange={filterClasses}
                    >
                      <option value="">All</option>

                      {costs.map((c) => (
                        <option key={c.id} value={c.slug}>
                          {c.title}
                        </option>
                      ))}
                    </Input>
                  </FormGroup>
                </Col>
              </Row>

              {filteredClasses.map((c) => (
                <Row className="mt-3" key={c.id}>
                  <div className="col-md-4">
                    <img
                      src={`/assets/images/${c.level.slug}-${c.type.slug}.png`}
                      className="img-fluid"
                      alt={c.title}
                    />
                  </div>
                  <div className="col-md-8 class-info-col">
                    <div className="class-header-row">
                      <div className="desc-col">
                        <div className="class-title">{c.title}</div>
                      </div>
                    </div>
                    <div className="class-info-row">
                      <div className="class-cost">
                        {c.price === 0
                          ? "Free"
                          : c.price.toLocaleString("en-US", {
                              style: "currency",
                              currency: "USD",
                              minimumFractionDigits: 2,
                            })}
                      </div>

                      <div className="class-level">{c.level.title}</div>
                      <div className="divider"></div>
                      <div className="class-type">{c.type.title}</div>
                    </div>
                    <div className="class-description-row">{c.description}</div>
                    <div className="class-register-row">
                      {c.isPaidCourse ? (
                        <StripeCheckout
                          stripeKey={process.env.REACT_APP_STRIPE_KEY}
                          token={registerForClass}
                          name={c.title}
                          amount={c.price * 100}
                          billingAddress
                        >
                          <Button color="primary">Register</Button>
                        </StripeCheckout>
                      ) : (
                        <Button
                          color="primary"
                          onClick={(e) => {
                            setSelectedClass(c);
                            setIsModalOpen(true);
                          }}
                        >
                          Register
                        </Button>
                      )}
                    </div>
                  </div>
                </Row>
              ))}
            </Col>
            <Col md="1" />
          </Row>
        </Container>
        <Modal isOpen={isModalOpen} toggle={toggle}>
          <ModalHeader toggle={toggle}>
            {`Register for ${selectedClass ? selectedClass.title : ""}`}
          </ModalHeader>
          <ModalBody>
            <Label for="registerEmail">Email</Label>
            <Input
              type="text"
              id="registerEmail"
              value={registerEmail}
              onChange={(e) => {
                const { value } = e.target;
                setRegisterEmail(value);
              }}
            />
          </ModalBody>
          <ModalFooter>
            <Button
              color="primary"
              onClick={() => registerForClass(selectedClass)}
              disabled={!isEmail(registerEmail)}
            >
              Register
            </Button>
            <Button
              color="secondary"
              onClick={() => {
                toggle();
                setRegisterEmail("");
              }}
            >
              Cancel
            </Button>
          </ModalFooter>
        </Modal>
      </section>
    </>
  );
};
export default Classes;
