import { useMutation } from 'react-apollo';
import { withRouter } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';

import { SEND_CONTACT_FORM } from '../queries/contact';
const ContactFormContainer = props => {
  const [mutate] = useMutation(SEND_CONTACT_FORM);
  const { addToast, removeAllToasts } = useToasts();

  async function submit(values) {
    const { data } = await mutate({
      variables: values
    });

    if (data) {
      return data.sendContactForm;
    }
    return null;
  }

  function sendToastAlert(message, options) {
    removeAllToasts();
    addToast(message, options);
  }

  return props.children({ submit, sendToastAlert });
};

export default withRouter(ContactFormContainer);
