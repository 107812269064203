import React from 'react';
import {
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Input,
  Container,
  Row,
  Col,
  Button,
  Form
} from 'reactstrap';
import Icon from 'react-icons-kit';
import { user } from 'react-icons-kit/fa/user';
import { envelope } from 'react-icons-kit/fa/envelope';
import { pencil } from 'react-icons-kit/fa/pencil';
import { withFormik } from 'formik';

import SectionHeader from './SectionHeader';
import { validateContactSchema } from '../../validation/contactForm';

const Contact = props => {
  const { values, errors, isSubmitting, handleChange, handleSubmit } = props;

  return (
    <section id="contact" className="py-5 section-grey">
      <Form onSubmit={handleSubmit}>
        <Container>
          <Row>
            <SectionHeader text="Contact Us" />
          </Row>
          <Row>
            <Col md="3" />
            <Col md="6" className="d-flex flex-column align-items-end">
              <InputGroup>
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <Icon icon={user} size="14" />
                  </InputGroupText>
                </InputGroupAddon>
                <Input
                  placeholder="Name *"
                  name="name"
                  id="name"
                  type="text"
                  onChange={handleChange}
                  value={values.name || ''}
                />
              </InputGroup>
              {errors.name ? (
                <div className="text-danger">{errors.name}</div>
              ) : null}
              <InputGroup>
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <Icon icon={envelope} size="14" />
                  </InputGroupText>
                </InputGroupAddon>
                <Input
                  placeholder="Email *"
                  name="email"
                  id="email"
                  type="text"
                  onChange={handleChange}
                  value={values.email || ''}
                />
              </InputGroup>
              {errors.email ? (
                <div className="text-danger">{errors.email}</div>
              ) : null}
              <InputGroup>
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <Icon icon={pencil} size="14" />
                  </InputGroupText>
                </InputGroupAddon>
                <Input
                  placeholder="Message *"
                  type="textarea"
                  rows="10"
                  name="message"
                  id="message"
                  onChange={handleChange}
                  value={values.message || ''}
                />
              </InputGroup>
              {errors.message ? (
                <div className="text-danger">{errors.message}</div>
              ) : null}
            </Col>
            <Col md="3" />
          </Row>

          <Row className="d-flex align-items-center justify-content-center">
            <Button
              className="form-button mx-2"
              type="submit"
              disabled={isSubmitting}
            >
              Submit
            </Button>
            <Button
              className="form-button mx-2"
              type="reset"
              disabled={isSubmitting}
            >
              Cancel
            </Button>
          </Row>
        </Container>
      </Form>
    </section>
  );
};
const ContactForm = withFormik({
  mapPropsToValues: () => ({ email: '', name: '', message: '' }),

  validationSchema: validateContactSchema,

  handleSubmit: async (
    values,
    { props, resetForm, setErrors, setSubmitting }
  ) => {
    try {
      props.sendToastAlert('Sending...', {
        appearance: 'info'
      });
      const { ok, errors } = await props.submit({ input: values });
      if (!ok) {
        setErrors(errors);
      } else {
        props.sendToastAlert('Email sent! We will respond to you shortly!', {
          appearance: 'success'
        });
      }
      setSubmitting(false);

      resetForm({});
    } catch (error) {
      props.sendToastAlert('Error sending email. Please try again', {
        appearance: 'error'
      });
    } finally {
      setSubmitting(false);
    }
  },

  displayName: 'SignupForm'
})(Contact);

export default ContactForm;
