import * as yup from 'yup';

const emailNotLongEnough = 'email must be at least 3 characters';
const emailRequired = 'Please enter an email address';
const invalidEmail = 'email must be a valid email';
const nameNotLongEnough = 'name must be at least 3 characters';
const messageNotLongEnough = 'name must be at least 10 characters';
const fieldRequired = 'This field is required';

export const validateContactSchema = yup.object().shape({
  email: yup
    .string()
    .min(3, emailNotLongEnough)
    .max(100)
    .email(invalidEmail)
    .required(emailRequired),
  name: yup
    .string()
    .min(3, nameNotLongEnough)
    .max(100)
    .required(fieldRequired),
  message: yup
    .string()
    .min(10, messageNotLongEnough)
    .max(500)
    .required(fieldRequired)
});

// const validate = values => {
//   const errors = {};
//   if (!values.name) {
//     errors.name = 'Required';
//   } else if (values.name.length < 2) {
//     errors.name = 'Must be more than two characters';
//   } else if (values.name.length > 20) {
//     errors.name = 'No more than 20 characters please';
//   }

//   if (!values.email) {
//     errors.email = 'Required';
//   } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
//     errors.email = 'Invalid email address';
//   }

//   if (!values.message) {
//     errors.message = 'Required';
//   } else if (values.message.length > 500) {
//     errors.message = 'No more than 500 characters please';
//   }

//   return errors;
// };
