import React from 'react';
import PropTypes from 'prop-types';
import Icon from 'react-icons-kit';
import { twitter } from 'react-icons-kit/fa/twitter';
import { github } from 'react-icons-kit/fa/github';
import { linkedin } from 'react-icons-kit/fa/linkedin';
import { facebook } from 'react-icons-kit/fa/facebook';
import { instagram } from 'react-icons-kit/fa/instagram';
import { youtube } from 'react-icons-kit/fa/youtube';

const getSocialUrl = ({ type, handle }) =>
  `http://www.${type}.com/${type === 'linkedin' ? 'in/' : ''}${handle}`;

const getSocialIcon = type => {
  const size = '18';
  const className = 'member-social-icon';
  switch (type.toLowerCase()) {
    case 'twitter':
      return <Icon icon={twitter} size={size} className={className} />;
    case 'github':
      return <Icon icon={github} size={size} className={className} />;
    case 'linkedin':
      return <Icon icon={linkedin} size={size} className={className} />;
    case 'facebook':
      return <Icon icon={facebook} size={size} className={className} />;
    case 'instagram':
      return <Icon icon={instagram} size={size} className={className} />;
    case 'youtube':
      return <Icon icon={youtube} size={size} className={className} />;
    default:
      return null;
  }
};

const TeamItem = ({ member, className }) => (
  <div
    className={`member ${
      className || ''
    } d-flex justify-content-center align-items-center flex-column`}>
    <img src={member.imageUri} alt={member.caption} title={member.title}></img>
    <div className="member-info mt-2">
      <span className="member-name mr-1">{member.name}</span>
      <span className="member-position">{member.position}</span>
    </div>

    {member.social ? (
      <div className="member-social-icons d-flex flex-row mt-2">
        {member.social.map((s, index) => {
          const { type, handle } = s;
          return (
            <div className="member-social-icon-container ml-1" key={index}>
              <a
                href={getSocialUrl({ type, handle })}
                target="_blank"
                rel="noopener noreferrer">
                {getSocialIcon(type)}
              </a>
            </div>
          );
        })}
      </div>
    ) : null}
  </div>
);
export default TeamItem;

TeamItem.propTypes = {
  member: PropTypes.object,
  className: PropTypes.string,
};
