import gql from 'graphql-tag';

export const SEND_CONTACT_FORM = gql`
  mutation SendContactForm($input: ContactFormInput!) {
    sendContactForm(input: $input) {
      ok
      message
      errors {
        path
        message
      }
    }
  }
`;
